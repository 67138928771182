<template>
  <div class="wheel-wrapper" :class="spinningClass">
    <div class="wheel">
      <img class="outer-wheel" src="../assets/outer-ring.png" alt="">
      <img class="pointer" src="../assets/pointer.png" alt="">
      <img class="inner-wheel" src="../assets/wheel-1.png" alt="">
      <img :class="{'most-inner-wheel': true,'spun': spinning}"
           @click="spinWheel"
           @click.prevent="openDuplicateTab"
           :src="pageWheelCenterImage || require('@/assets/inner-circle.png')" alt=""
           tabindex="0"
           @keydown.enter="spinWheel"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'Wheel',
  emits: ['wheel-spun', 'brand-data-loaded'],
  props: ['pageData'],
  data() {
    return {
      pageWheelCenterImage: '',
      spinning: false,
      spun: false,
    };
  },
  created() {
    this.landingPage = this.getParameterByName('lpg') || window.location.hostname;
  },
  methods: {
    spinWheel() {
      if (this.spinning || this.spun) return;
      this.spinning = true;
      window.dataLayer.push({ event: 'spin_wheel' });
      this.getBrand();
      setTimeout(() => {
        this.spun = true;
        window.dataLayer.push({ event: 'pop_up_appear' });
        this.$emit('wheel-spun', true);

        // this.$confetti.start({
        //   particles: [
        //     {
        //       type: 'rect',
        //     },
        //   ],
        // });
      }, 5500);
    },
    getParameterByName(paramName, url = window.location.href) {
      const name = paramName.replace(/[[\]]/g, '\\$&');
      const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
      const results = regex.exec(url);
      if (!results) return false;
      if (!results[2]) return false;
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    async getBrand() {
      try {
        // get LandingPage value from url param landingpage (set in created hook)
        const response = await this.axios.get(`${process.env.VUE_APP_API}/brands/v1/offer/?hostname=${this.landingPage}`);
        this.brandData = response.data.brandData !== undefined ? response.data : null;
        this.$emit('brand-data-loaded', this.brandData);
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    spinningClass() {
      const minNumber = 1;
      const maxNumber = 5;
      return this.spinning ? `spinning-${Math.floor(Math.random() * (maxNumber - minNumber) + minNumber)}` : 'static';
    },
  },
  mounted() {
    this.pageWheelCenterImage = this.pageData.pageWheelCenterImage;
  },
};
</script>

<style lang="scss">
  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(3600deg);
    }
  }
  @keyframes spin2 {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(3563deg);
    }
  }
  @keyframes spin3 {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(3636deg);
    }
  }
  @keyframes spin4 {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(3672deg);
    }
  }
  @keyframes spin5 {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(3743deg);
    }
  }
  .wheel-wrapper {
    padding: 1.25rem 0;
    overflow: hidden;
    &.spinning-1 {
      .inner-wheel {
        animation-name: spin;
        animation-duration: 5s; /* or: Xms */
        animation-iteration-count: 1;
        animation-timing-function: cubic-bezier(.7,.15,.44,1.33);
        animation-fill-mode: forwards; /* or: backwards, both, none */
      }
    }
    &.spinning-2 {
      .inner-wheel {
        animation-name: spin2;
        animation-duration: 5s; /* or: Xms */
        animation-iteration-count: 1;
        animation-timing-function: cubic-bezier(.7,.15,.44,1.33);
        animation-fill-mode: forwards; /* or: backwards, both, none */
      }
    }
    &.spinning-3 {
      .inner-wheel {
        animation-name: spin3;
        animation-duration: 5s; /* or: Xms */
        animation-iteration-count: 1;
        animation-timing-function: cubic-bezier(.7,.15,.44,1.33);
        animation-fill-mode: forwards; /* or: backwards, both, none */
      }
    }
    &.spinning-4 {
      .inner-wheel {
        animation-name: spin4;
        animation-duration: 5s; /* or: Xms */
        animation-iteration-count: 1;
        animation-timing-function: cubic-bezier(.7,.15,.44,1.33);
        animation-fill-mode: forwards; /* or: backwards, both, none */
      }
    }
    &.spinning-5 {
      .inner-wheel {
        animation-name: spin5;
        animation-duration: 5s; /* or: Xms */
        animation-iteration-count: 1;
        animation-timing-function: cubic-bezier(.7,.15,.44,1.33);
        animation-fill-mode: forwards; /* or: backwards, both, none */
      }
    }
  }
  .wheel {
    width: 584px;
    height: 584px;
    max-width: 100%;
    position: relative;
    margin: 0 auto;
  }
  .most-inner-wheel {
    display: block;
    position: absolute;
    left: 35%;
    top: 30%;
    width: 200px;
    transition: 0.1s ease;
    transform: scale(1);
    animation: pulse 2s infinite;
    border-radius: 50%;
    z-index: 99;
  }
  @keyframes pulse {
    0% {
      transform: scale(0.95);
    }
    70% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(0.95);
    }
  }
  .most-inner-wheel:hover {
    cursor: pointer;
    &.spun {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  .outer-wheel {
    display: block;
    max-width: 100%;
    position: relative;
    z-index: 1;
  }
  .inner-wheel {
    position: absolute;
    left: 52%;
    top: 46%;
    transform: translate(-50%, -50%) rotate(0deg);
    width: 88%;
    @media (max-width: 1023px) {
      left: 52%;
      top: 50%;
    }
  }
  img.pointer {
    position: absolute;
    top: 41%;
    left: 9%;
    transform: translateX(-50%);
    z-index: 1;
    width: 104px;
    @media (max-width: 1023px) {
      top: 200px;
    }
  }
  @media (max-width: 1215px) {
    .wheel {
      width: 500px;
      height: 500px;
    }
    .most-inner-wheel {
      width: 182px;
      left: 34%;
      top: 29%;
    }
    .pointer {
      width: 80px;
      top: 210px;
    }
  }
  @media (max-width: 1023px) {
    .most-inner-wheel {
      width: 150px;
    }
    .pointer {
      top: 196px;
    }
    .most-inner-wheel {
      top: 33%;
      left: 36%;
    }
    img.inner-wheel {
      left: 50%;
      top: 53%;
    }
    img.most-inner-wheel {
      top: 37%;
      left: 34%;
    }
    img.pointer {
      transform: rotate(90deg);
      top: 0;
      left: 39%;
    }
    img.outer-wheel {
      transform: rotate(90deg);
    }
  }
  @media (max-width: 500px) {
    img.most-inner-wheel {
      left: 35%;
      top: 36%;
    }
    img.pointer {
      width: 70px;
      left:43%;
    }
  }
  @media (min-width: 375px) and (max-width: 440px) {
    img.most-inner-wheel {
      left: 32%;
      top: 33%;
    }
    img.pointer {
      width: 70px;
      left: 42%;
    }
  }
  @media (max-width: 375px) {
    img.pointer {
      width: 65px;
      left: 42%;
    }
    img.most-inner-wheel {
      left: 37%;
      width: 100px;
      top: 38%;
    }
  }
  @media (max-width: 320px) {
    img.pointer {
      width: 45px;
      left: 43%;
    }
    img.most-inner-wheel {
      left: 34%;
      top:36%;
      width: 100px;
    }
  }
</style>
